













































.actions-section {
  background: #4e5468;
  .header {
    background-color: #353a4a;
    padding: 6px 0;
    text-align: center;
    color: #FFF;
    font-weight: 700;
  }
  .search {
    padding: 10px;
    input {
      border: none;
      width: 100%;
      padding: 5px 7px 5px 9px;
      border-radius: 4px;
    }
  }
  .action-header {
    padding: 16px;
    background: #353a4a;
    color: #FFF;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
  }
  .action-group {
    .action-header {
      background: #4e5468;
      border-top: 1px solid #353a4a;
      &:last-child {
        border-bottom: none;
      }
      .uil {
        float: right;
      }
    }
    &.open {
      .action-header {
        background: #353a4a;
      }
    }
  }
  .list-actions {
    padding-bottom: 10px;
    .action-item {
      cursor: move;
      padding: 11px;
      color: #FFF;
      font-size: 12px;
      font-weight: 700;
      background: #353a4a;
      border-radius: 5px;
      user-select: none;
      margin: 10px 10px 0px 10px;
      .uil {
        float: right;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
